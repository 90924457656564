import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'

import dynamic from "next/dynamic"

const SFTable = dynamic(
  () => import('../components/sf/table').then(modules =>  modules.SFTable) as any, 
  {ssr: false}
)

const Home: NextPage = () => {

  return (
    <div className={styles.container}>
      <Head>
        <title>Shinobidle</title>
        <meta name="description" content="shinobidle" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>
          Shinobidle
        </h1>
        <a href="#help" className="top-0 right-0 absolute text-3xl px-4 py-3 m-2 rounded-md border border-zinc-500 text-zinc-500 hover:no-underline">?</a>
        <SFTable />
        <hr />
        <div>

        </div>
      </main>
    </div>
  )
}

export default Home
